<template>
    <el-row>
      <el-col :class="$langConfig.cssClass" :style="'width:' + labelWidth ">
        <span class="field-mark optional"></span>
        <dg-label-field :label="label" :translate="false"></dg-label-field>
      </el-col>
      <el-col :class="$langConfig.cssClass" style="width: 60px">
        <dg-text-field
            :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
            :field_span="23"
            :label_span="24"
            :translateLabel='false'
            field_mark=""
            label=""
            :name="phoneAreaPrefixName"
            :valueFormatter="(val) => {return mask2(unmask(val)) }"
            maxLength="5"
            placeholder="(xxx)"
            :liveUpdate="true"
            @amUpdated="part2Updated"
            :rules="rules"></dg-text-field>
      </el-col>
      <el-col :class="$langConfig.cssClass" style="width: 85px;">
        <dg-text-field
            :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
            :field_span="24"
            :label_span="24"
            :translateLabel='false'
            field_mark=""
            label=""
            :name="phoneNumberName"
            placeholder="123-4567"
            :liveUpdate="true"
            @amUpdated="part1Updated"
            maxLength="8"
            :rules="rules"></dg-text-field>
      </el-col>
      <el-col :class="$langConfig.cssClass" style="width: 43px; margin-left: 5px" v-if="withExtension">
        <dg-text-field
            :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
            :field_span="24"
            :label_span="0"
            :translateLabel='false'
            field_mark=""
            label=""
            maxLength="3"
            :name="phoneNumberExtName"
            placeholder="Ext"
            rules=""></dg-text-field>
      </el-col>
    </el-row>
</template>

<script>
export default {
  methods: {
    mask2 (value) {
      let output = [];
      let len = value.length
      let d = false
      let d2 = false
      //if (len == 0) return "(xxx)"
      for(let i = 0; i < value.length; i++) {
        if( i == 0 && !d) {
          d = true
          output.push("("); // add the separator
        }
        if(i == 2 && !d2) {
          d2 = true
          output.push(value[i]);
          output.push(")"); // add the separator
          return output.join("")
        }
        output.push(value[i]);
      }
      if (len !== 0) output.push(")")
      return output.join("")
    },
    part2Updated (props) {
      let value = props.field.field_value
      value = this.unmask(value)
      props.field.field_value =  this.mask2(value);
    },
    mask (value) {
      let output = [];
      let len = value.length
      let d = false
      for(let i = 0; i < len; i++) {
        if( i !== 0 && (i % 3 === 0 && len > 3 && !d) ) {
          d = true
          output.push("-"); // add the separator
        }
        output.push(value[i]);
      }
      return output.join("")
    },
    unmask (val) {
      return val.replace(new RegExp(/[^\d]/, 'g'), ''); // Remove every non-digit character
    },
    part1Updated (props) {
      let value = props.field.field_value
      value = this.unmask(value)
      props.field.field_value =  this.mask(value);
    }
  },
  props: {
    labelWidth: {
      default: "60px"
    },
    rules: {
      default: ""
    },
    label: {
      required :true
    },
    control: {
      required :true
    },
    editMode: {
      required :true
    },
    phoneAreaPrefixName: {
      default: 'contact_work_phone_area_code'
    },
    phoneNumberName: {
      default: 'contact_work_phone'
    },
    phoneNumberExtName: {
      default: 'contact_work_phone_extension'
    },
    withExtension: {
      required :false,
      default: true
    }
  }
}
</script>
